import { type FC } from "react";
import { Text } from "~/modules/common/components/atoms/Text";
import { ButtonPrimary } from "~/modules/common/components/atoms/buttons/ButtonPrimary";

const RedirectSignup: FC = () => {
  return (
    <div className="flex flex-col gap-3 text-center">
      <Text size="bodyRegular">Pas encore de compte ?</Text>
      <ButtonPrimary href="/onboarding" size="large" variant="quaternary">
        S&apos;inscrire maintenant
      </ButtonPrimary>
    </div>
  );
};

export default RedirectSignup;
