import React, {
  type FC,
  type PropsWithChildren,
  type DetailedHTMLProps,
  type HTMLAttributes,
} from "react";

export type LineProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    className: string;
  }>;

const Line: FC<PropsWithChildren<LineProps>> = ({
  className,
  ...restProps
}) => {
  return <div className={className} {...restProps} />;
};
export { Line };
