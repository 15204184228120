import {
  type ButtonHTMLAttributes,
  type DetailedHTMLProps,
  type FC,
  type ReactNode,
} from "react";
import { Text } from "~/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";

interface ConnectionButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  clasName?: string;
  icon?: ReactNode;
}

export const ConnectionButton: FC<ConnectionButtonProps> = ({
  children,
  icon,
  className,
  ...rest
}) => {
  return (
    <button
      className={cn(
        "flex h-14 min-h-fit w-full items-center justify-center gap-3 rounded-xl border border-slate-500 bg-new-light-4 px-4 py-3 text-new-dark-0",
        className,
      )}
      {...rest}
    >
      {icon && icon}
      <Text size="medium" className="text-center">
        {children}
      </Text>
    </button>
  );
};
