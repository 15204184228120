"use client";
import { signIn } from "next-auth/react";
import { type FC } from "react";
import { FcGoogle } from "react-icons/fc";
import { Line } from "~/modules/common/components/atoms/Line";
import { Text } from "~/modules/common/components/atoms/Text";
import { AuthProvider } from "~/server/auth/enums";
import { ConnectionButton } from "../atoms/ConnectionButton";
import RedirectSignup from "../molecules/RedirectSignup";
import LoginForm from "../organisms/LoginForm";

const LoginPageComponent: FC = () => {
  return (
    <div className="flex flex-col items-center justify-between gap-8 px-2 xl:px-10">
      <Text className="px-8 text-center text-new-dark-0" size="heading2">
        Connectez-vous
      </Text>
      <ConnectionButton
        icon={<FcGoogle className="h-6 w-6" />}
        onClick={() => signIn(AuthProvider.Google)}
        className="w-[351px] rounded-[100px] border-0 bg-new-light-4 shadow-1"
      >
        Se connecter avec Google
      </ConnectionButton>
      <Line className="w-full border-t border-new-light-0" />
      <LoginForm />
      <Line className="w-full border-t border-new-light-0" />
      <RedirectSignup />
    </div>
  );
};

export default LoginPageComponent;
