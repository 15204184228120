"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { signIn } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import { useState, type FC } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ButtonPrimary } from "~/modules/common/components/atoms/buttons/ButtonPrimary";
import { Form } from "~/modules/common/components/atoms/form/form";
import { Infobox } from "~/modules/onboarding/components/molecules/InfoBox";
import { type Credentials } from "~/modules/onboarding/interfaces";
import { AuthProvider } from "~/server/auth/enums";
import Spinner from "../../../common/components/atoms/Spinner";
import { schemaCredentials } from "../../../onboarding/schemas";
import GenericInput from "../atoms/GenericInput";
import RedirectPasswordRecovery from "../molecules/RedirectPasswordRecovery";

const LoginForm: FC = () => {
  const form = useForm<Credentials>({
    resolver: zodResolver(schemaCredentials),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const searchParams = useSearchParams();
  const parsedParamServerError = z
    .string()
    .safeParse(searchParams.get("error"));
  const serverError = parsedParamServerError.success
    ? parsedParamServerError.data
    : null;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function onSubmit(values: z.infer<typeof schemaCredentials>) {
    setIsLoading(true);
    try {
      await signIn(AuthProvider.Credentials, {
        ...values,
        callbackUrl: "/auth/login",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex w-full flex-col items-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col items-center justify-center gap-6"
        >
          <div className="flex w-full flex-col gap-[6px]">
            <GenericInput
              name="email"
              label="Adresse mail"
              control={form.control}
              placeholder="Entrez votre adresse mail"
            />
            <div className="flex flex-col">
              <GenericInput
                name="password"
                label="Mot de passe"
                control={form.control}
                isHiddenMode
                showErrorMessage={false}
              />
              <RedirectPasswordRecovery />
            </div>
          </div>
          {serverError && (
            <Infobox
              className="w-full rounded-md bg-new-corail-4 text-new-dark-0"
              theme="red"
            >
              {serverError === "OAuthAccountNotLinked"
                ? "Afin de vous connecter, veuillez utiliser l’email avec lequel vous vous êtes inscrit"
                : "Identifiants incorrects"}
            </Infobox>
          )}
          <div className="flex items-center gap-4">
            <ButtonPrimary
              type="submit"
              size="large"
              variant="secondary"
              disabled={isLoading}
              className="w-fit max-w-full px-3 py-2"
            >
              Me connecter
            </ButtonPrimary>
            {isLoading && <Spinner className="h-8 w-8" />}
          </div>
        </form>
      </Form>
    </div>
  );
};

export default LoginForm;
