import Link from "next/link";
import { type FC } from "react";
import { Text } from "~/modules/common/components/atoms/Text";

const RedirectPasswordRecovery: FC = () => {
  return (
    <div className="flex flex-col gap-1 xs:flex-row">
      <Link href="/auth/password/recovery">
        <Text className="text-new-primary-1 hover:underline" size="lead">
          Mot de passe oublié ?
        </Text>
      </Link>
    </div>
  );
};

export default RedirectPasswordRecovery;
