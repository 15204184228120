import { type FC, type ReactNode } from "react";
import IconInfo from "~/modules/common/components/atoms/icons/IconInfo";
import { Text } from "~/modules/common/components/atoms/Text";
import { cn } from "../../../common/components/utils/styles";

interface InfoBoxProps {
  children?: ReactNode;
  className?: string;
  theme?: "green" | "red";
  renderIcon?: () => ReactNode;
}

export const Infobox: FC<InfoBoxProps> = ({
  children,
  className,
  theme = "green",
  renderIcon,
}) => {
  return (
    <div
      className={cn(
        "flex flex-row items-center gap-[10px] rounded-lg bg-new-primary-3 p-[15px] text-new-dark-0",
        className,
        theme === "green" ? "bg-new-primary-3" : "bg-new-corail-4",
      )}
    >
      {renderIcon ? (
        renderIcon()
      ) : (
        <div className="shrink-0">
          <IconInfo
            className={cn(
              "h-8 w-8",
              theme === "green" ? "fill-new-primary-1" : "fill-new-corail-1",
            )}
          />
        </div>
      )}
      <Text size="bodyRegular">{children}</Text>
    </div>
  );
};
